export const Const = {
  Commission: 25,
  PhoneNumber: {
    Pattern:
      /^(?=(?:\+|0{2})?(?:(?:[(\-).\/ \t\f]*\d){7,10})?(?:[\-.\/ \t\f]?\d{2,3})(?:[\-\s]?[ext]{1,3}[\-.\/ \t\f]?\d{1,4})?$)((?:\+|0{2})\d{0,3})?(?:[\-.\/ \t\f]?)(\(0\d[ ]?\d{0,4}\)|\(\d{0,4}\)|\d{0,4})(?:[\-.\/ \t\f]{0,2}\d){3,8}(?:[\-\s]?(?:x|ext)[\-\t\f ]?(\d{1,4}))?$/,
    MinLength: 5,
    MaxLength: 24,
  },
  UserName: {
    Pattern: /^[a-zA-Z0-9]([._](?![._])|[a-zA-Z0-9]){1,28}[a-zA-Z0-9]$/,
    MinLength: 4,
    MaxLength: 30,
  },
  Password: {
    MinLength: 8,
    MaxLength: 100,
  },
  Address: {},
  Accounts: {
    MaxEmailAddress: 256,
    MinName: 2,
    MaxFirstName: 32,
    MaxLastName: 32,
  },
  Proposals: {
    OfferMin: 500,
    DetailsMaxLength: 4096,
  },
  Milestones: {
    TitleMinLength: 1,
    TitleMaxLength: 64,
    MilestoneDescriptionMaxLength: 2048,
    Less5KMax: 1,
    Between5KTo10KMax: 2,
    Between10KTo30KMax: 3,
    Greater30KMax: 4,
  },
  Jobs: {
    MinTitle: 10,
    MaxTitle: 100,
    DescriptionMinLength: 50,
    DescriptionMaxLength: 4096,
    MinBudgetFrom: 500,
    MinSkills: 4,
    MaxSkills: 64,
    MaxSkillName: 50, // TODO:
    MaxAttachmentSize: 10 * 1024 * 1024,
  },
  Clients: {
    MinTitle: 2,
    MaxTitle: 100,
    MinCompanyName: 2,
    MaxCompanyName: 100,
  },
  Freelancers: {
    TitleMinLength: 2,
    TitleMaxLength: 50,
    BioMaxLength: 4000,
    BioMinLength: 50,
  },
  pictureMaxSize: 5 * 1024 * 1024,
  coverImgMaxSize: 5 * 1024 * 1024,
  portfolioImgMaxSize: 5 * 1024 * 1024,
  minRequiredAge: 18,
  searchQueryMaxLength: 128,
  changeStateNotesMaxLength: 4096,
  idMaxLength: 32,
  streetMaxLength: 32,
  stateMaxLength: 64,
  cityMaxLength: 64,
  postalCodeMaxLength: 16,
  fileNameMaxLength: 256,
  fileLocalUrlMaxLength: 128,
  linkMaxLength: 2048,
  socialLinkMaxLength: 512,
  notificationDetailsMinLength: 20,
  notificationDetailsMaxLength: 1024,
  notificationDetailsUrlMaxLength: 256,
  educationTitlesMaxLength: 256,
  messageMaxLength: 4096,
  proposalDetailsMaxLength: 4096,
  milestoneDetailsMaxLength: 4096,
  portfolioDescriptionMaxLength: 4096,
  educationDescriptionMaxLength: 4096,
  experienceDescriptionMaxLength: 4096,
  certificationDescriptionMaxLength: 4096,
  emailBodyMinLength: 50,
  emailSubjectMinLength: 10,
  emailSubjectMaxLength: 256,
  titleMinLength: 2,
  titleMaxLength: 256,
};
